.eventDetailsPage {
  display: flex;
  justify-content: center !important;
  padding: 0 !important;
  margin: 4rem 0 !important;
  width: 100%;
}

.textStyle {
  font-size: 21px;
  margin: 0 !important;
}

.heroSectionCol {
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

@media (max-width: 991px) {
  .heroSectionCol {
    padding: 2rem 0;
  }
}

.heroCard {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
}

.heroCardListItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.basicIcon {
  color: #8b4513;
  min-width: 2rem;
}

.registerButton {
  width: auto;
  font-weight: 600;
  background-color: #228b22;
}

.registerButton:hover {
  background-color: #228b22a9 !important;
}

.herCardButtonDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 2rem;
}

.scrollDownInstructionsDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 991px) {
  .herCardButtonDiv {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .registerButton {
    width: 100%;
    justify-content: center;
  }

  .scrollDownInstructionsDiv {
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.cardText {
  margin-bottom: 10px; /* space between paragraphs */
}

.mapSectionCol {
  padding: 4rem 0 !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

@media (max-width: 991px) {
  .mapSectionCol {
    padding: 1rem 0 2rem 0 !important;
  }
}

@media (max-width: 991px) {
  .sectionTwoCol {
    padding: 2rem 0 2rem 0 !important;
  }
}

.sectionSubTitle {
  font-size: 20px;
  font-weight: 600;
  color: #8f8e8e;
  padding: 0 !important;
  margin: 0 !important;
  color: #8b4513 !important;
}

.level1SectionTitle {
  font-size: 40px;
  color: #616161 !important;
  margin: 0 0 1.5rem 0 !important;
  color: #228b22 !important;
  text-wrap: wrap !important;
  word-break: keep-all !important;
}

.level2SectionTitle {
  font-size: 36px !important;
  font-weight: 600 !important;
  padding: 0 0 1rem 0 !important;
  width: fit-content;
  margin: 0 !important;
  color: #228b22 !important;
  text-wrap: wrap !important;
  text-align: left;
}

.level3SectionTitle {
  font-size: 28px !important ;
}

.level4SectionTitle {
  font-size: 28px;
}

.level5SectionTitle {
  font-size: 24px;
}

.sectionTitle {
  padding: 0.25rem 0 2rem 0 !important;
  margin: 0 !important;
}

/* Last item border removal */
.custom-list-item:last-child {
  border-bottom: none;
}

@media (max-width: 991px) {
  .innerCard .ant-card .ant-card-body {
    padding: 0 !important;
  }

  .innerCard {
    padding: 0 !important;
  }
  .innerCardListItem {
    padding: 0.75rem 0 !important;
    font-size: 21px;
  }
}

.innerCardListItem > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionRegisterCol {
  margin: 4rem 0 6rem 0 !important;
}

.registrationFlexDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}

@media (max-width: 991px) {
  .imageCol {
    display: none;
  }
}

.includedSectionCol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.includedContainerDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  margin: 0;
}

.includedCard {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  background: #ffffff;
  width: 500px;
  text-wrap: wrap;
}

@media (max-width: 991px) {
  .includedContainerDiv {
    justify-content: center;
  }
  .includedCard {
    width: 100%;
  }
}
