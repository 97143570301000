.homePageFlex {
  margin: 4rem 0;
}

@media (max-width: 600px) {
  .homePageFlex {
    margin: 0;
  }
}

.textStyle {
  font-size: 23px !important;
  padding: 0;
  margin: 0;
}

.levelOneFont {
  font-size: 40px !important;
}

.levelTwoFont {
  font-size: 36px !important;
  font-weight: 600 !important;
}

.levelThreeFont {
  font-size: 28;
}

.levelFourFont {
  font-size: 25px !important;
  font-weight: 600 !important;
}

@media (max-width: 600px) {
  .heroImage {
    display: none;
  }
}

/* EVENT COMPONENT STYLES*/
.eventCard {
  display: flex;
  min-width: 450px;
  max-width: 450px;
  padding: 1rem;
}

@media (max-width: 991px) {
  .eventCard {
    display: block;
    min-width: 100%;
    padding: 1rem 0rem;
  }

  .eventCardButton {
    width: 100% !important;
  }
}

.eventCardButton {
  border-color: #228b22 !important;
  color: #8b4513 !important;
  font-size: 22px;
  height: 3rem;
  width: 12rem !important;
}

.eventCardButton:hover {
  background-color: #228b223c !important;
  border-color: #228b22 !important;
}

.cardSubTitle {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #8b4513;
}

.eventCardRow {
  padding: 4rem 5rem;
  margin: 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .eventCardRow {
    padding: 3rem 0.5rem;
    gap: 2rem;
  }
}

/* write media querues for card compoennt, in dsektop should show max of 3 in a line. in tablet and mobile, should show 1 card per line */

/* Home Page About Section */
.aboutSectionRow {
  margin: 4rem;
  gap: 6rem;
}

@media (max-width: 768px) {
  .aboutSectionRow {
    margin: 0;
    margin-top: 3rem;
    align-content: center;
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .aboutRow {
    gap: 1rem;
  }
}

.eventsButton {
  background-color: #228b22;
  font-weight: 600;
}

.eventsButton:hover {
  background-color: #228b22a9 !important;
}

.secondaryBtn {
  background-color: #8b4513;
  color: #ffffff;
  font-weight: 600;
}

.secondaryBtn:hover {
  background-color: #8b4513a9 !important;
}
