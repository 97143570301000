.footerRow {
  padding: 4rem 0;
  width: 100%;
}

@media (max-width: 992px) {
  .footerRow {
    row-gap: 2rem;
  }
}

.textStyle {
  color: #228b22 !important;
}

.textStyle:hover {
  color: #228b22a9 !important;
}
