.navBar {
  height: 6rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.logoDiv {
  position: absolute;
  left: 3rem;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .navBar {
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }

  .logoDiv {
    position: relative;
    left: 0;
  }

  .drawer {
    width: 100%;
  }

  .navMenuIconDiv {
    display: flex;
    justify-content: center;
  }
}

.menuIcon {
  font-size: 4rem;
}

.navMenuSpan {
  display: block;
}

.navLogo {
  height: 4rem !important;
}

@media (min-width: 991px) {
  .navMenuIconDiv {
    display: none;
  }
}

.menuItem {
  border-radius: 0.5rem;
  border: #228b22 1px solid;
}
