.registrationPage {
  padding: 6rem 0 4rem 0;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.editFormButton {
  margin: 1rem 0;
  width: auto;
}

.registrationConfirmationButtonDiv {
  display: flex;
  justify-content: space-between;
}

.confirmationButton {
  margin: 1rem 0;
  width: auto;
}

@media (max-width: 991px) {
  .registrationPage {
    padding: 3rem 0 2rem 0;
    gap: 1rem;
  }

  .skillLevelItemContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 0.5rem;
  }
}
